@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 200;
  src: url(/fonts/Mukta/Mukta-ExtraLight.ttf);
}

@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 300;
  src: url(/fonts/Mukta/Mukta-Light.ttf);
}

@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Mukta/Mukta-Regular.ttf);
}

@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 500;
  src: url(/fonts/Mukta/Mukta-Medium.ttf);
}

@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/Mukta/Mukta-SemiBold.ttf);
}

@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/Mukta/Mukta-Bold.ttf);
}

@font-face {
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 800;
  src: url(/fonts/Mukta/Mukta-ExtraBold.ttf);
}

@font-face {
	font-family: 'IM FELL English Italic';
	src: url(/fonts/IM-FELL-English-Italic.woff2);
}

@font-face {
	font-family: 'Pinyon Script';
	font-style: normal;
	font-weight: 400;
	src: local('Pinyon Script'), local('PinyonScript'), url(/fonts/Pinyon-Script.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: 16px * $ratio;
}

@media only screen and (min-width: 0px) and (max-width : 722px)  {
  #mc_embed_signup {
    padding: 40px 15px 40px 15px !important;
  }
  #mce-EMAIL {
    margin-bottom: 10px;
    margin-right: 0 !important;
    text-align:center;
  }
  #mc-embedded-subscribe-form > h1 {
    margin-bottom: 10px;
  }
  #email-input {
    margin: 20px 0px !important;
  }
  div.duesLevels {
    left: 0 !important;
    width: 100% !important;
  }
  #link-nav {
    display: none;
  }
  #select-nav {
    display: block !important;
  }
  #current-selection {
    display: block !important;
  }
  #IWWlogo {
    margin-top: 20px !important;
  }
  #events-main-info {
    width: default !important;
    padding-left: 0px !important;
    padding-top: 15px;
  }
  .date-box {
    float: none !important;
  }
  .about-image {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 20px;
    float: none !important;
    max-width: 100% !important;
    padding: 0px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width : 715px)  {
  .pp-selection {
    height: 220px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width : 350px)  {
  .pp-selection {
    height: 275px !important;
  }
}

.titleIWW {
  font-family: 'Mukta', sans-serif;
  font-weight: 700;
  letter-spacing: 7px;
  text-transform: uppercase;
  line-height: 1.8em;
  font-size: 16px;
  color: hsl(0, 0%, 29%);
  text-align:center;
  margin-top:26px;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.next-meeting-header {
  text-align: center;
  background-color: rgba(34,34,34,1);
  color: #FFFFFF;
  padding: 8px;
  font-family: 'Mukta', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 20px;
}

.next-orientation-header {
  text-align: center;
  background-color: rgba(34,34,34,1);
  color: #FFFFFF;
  padding: 8px;
  font-family: 'Mukta', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 20px;
}

.alert-header {
  display: none;
  text-align: center;
  background-color: rgba(34,34,34,1);
  color: #FFFFFF;
  padding: 8px;
  font-family: 'Mukta', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 20px;
}

.current {
  color: hsl(356, 68%, 41%) !important;
}

#dropdown-content {
  display: none;
  position: absolute;
}

#dropdown-content a {
  display: block;
  z-index: 1;
  background-color: rgba(252, 252, 252, 0.9);
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
  border-left: 3px solid hsl(356, 68%, 41%);
}

#dropdown:hover #dropdown-content {
  display: block;
}

#dropdown {
  display: inline-block;
}

button {
  font-family: 'Mukta', sans-serif;
  font-weight: 700;
	text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
	text-decoration: none;
	display: inline-block;
	background-color: transparent;
	color: rgba(39,39,39,1);
	border: 2px solid rgba(39,39,39,1);
  cursor: pointer;
  -webkit-transition-duration: 0.22s;
  transition-duration: 0.22s;
}

button:hover {
	background-color: rgba(39,39,39,1);
	color: white;
  -webkit-transition-duration: 0.12s;
	transition-duration: 0.12s;
}

button:active {
	background-color: #000;
  border: 2px solid #000;
	color: white;
  -webkit-transition-duration: 0.12s;
	transition-duration: 0.12s;
}

button.large {
	font-size: 16px;
  padding: 17px 36px 17px 36px;
}

button.small {
  font-size: 12px;
  padding: 13px 26px 13px 26px;
}

button.newsletter {
  font-size: 16px;
  padding: 11px 36px 9px 36px;
}

hr {
  border:.5px solid #BBBBBB;
}

.page-title {
  font-size: 36px;
  text-align: center;
  margin-bottom: 35px;
  line-height: 1;
}

.social-icons {
  display: inline-flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  svg {
    width: 50px;
    height: 50px;
    fill: #000;
  }

  a {
    text-decoration: none;
    transition: opacity 0.4s;
    transform: translateZ(0);
  }

  &:hover > a {
    opacity: 0.3;
  }

  &:hover a:hover {
      opacity: 1.0 !important;
  }
}

#footer_text {
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  word-spacing: 1px;
  color: #585858;
  margin-top: 16px;
}

ul li {
  margin-bottom: 12px;
}

ol li {
  margin-bottom: 12px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.date-box {
  float: left;
  margin-right: 35px;
  background: #E8ECEC;
  color: #424242;
  height: 72px;
  width: 72px;
  text-align: center
  ;display: flex;
  justify-content: center;
  align-items: center;
}

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: rgba(33,33,33,1);
  padding-bottom: 10px;
}

/**
 * Basic styling
 */
body {
  font-family: 'Mukta', sans-serif;
  font-weight: 400;
  line-height: 1.7em;
  color: rgba(66,66,66,1);
  background-color: #fdfdfd;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: 30px / 2;
}

/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}

/**
 * Lists
 */
ul, ol {
  margin-left: 30px;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/**
 * Links
 */
a {
  color: hsl(356, 68%, 41%);
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(240, 148, 153, 0.3) !important;

  &:hover {
    color: hsl(356, 100%, 20%);
    text-decoration: none;
  }
}

/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{825px} - (#{30px} * 2));
  max-width:         calc(#{825px} - (#{30px} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
  @extend %clearfix;

  @include media-query(820px) {
    max-width: -webkit-calc(#{825px} - (#{30px}));
    max-width:         calc(#{825px} - (#{30px}));
    padding-right: 30px / 2;
    padding-left: 30px / 2;
  }
}

::selection {
  background: #F09499;
}

::-moz-selection {
  background: #F09499;
}

/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

@charset "utf-8";

/**
 * Site header
 */
.site-header {
  border-top: 0px;
  border-bottom: 0px;
  min-height: 30px * 1.865;
  position: relative;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: 1.5 * 16px * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: #828282-dark;
  }
}

#select-nav {
  margin-right: auto;
  margin-left: auto;
  border: none;
  font-size: 16px;
  display: none;
  max-width: 85%;
  position: absolute !important;
  opacity: 0;
  vertical-align: middle;
}

#current-selection {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.3em;
  margin-right: auto;
  margin-left: auto;
  font-size: 24px;
  color: hsl(356, 68%, 41%);
  text-transform: uppercase;
  display: none;
  max-width: 85%;
}

.site-nav {
  text-align: center;
  font-family: 'Mukta', sans-serif;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-top: 45px;
  margin-bottom: 20px;
  color: hsl(0, 0%, 23%);

    & a:hover {
      color: hsl(356, 68%, 41%);
    }
  }

.page-link {
    color: rgb(59, 59, 59);
    line-height: 1.5;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 26px;
  }
}

/**
 * Site footer
 */
.site-footer {
  padding: 30px 0;
}

/**
 * Page content
 */
.page-content {
  padding: 30px 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
  text-align: center;
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: 30px;
  }
}

.post-meta {
  font-size: 14px;
  color: #828282;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

/**
 * Posts
 */
.post-header {
  margin-bottom: 30px;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;

  @include media-query(820px) {
    @include relative-font-size(2.25);
  }
}

.event img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
